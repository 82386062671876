<template>

  <div class="popup" style="font-size: 13pt;">
    <div v-for="(tr, indextr) in debitNote" :data="tr" :key="indextr">
      <div style="margin-bottom: 2%" class="pagebreak">
        <div style="justify-center: end; display: flex">
          <div style="width: 100%" align="center">
              <h3><b>DEBIT NOTE</b></h3>
          </div>
        </div>
      </div>
      <br/>
      <div class="d-flex">
        <div style="width: 50%">
          <table width="100%">
            <tbody>
              <tr>
                <td style="vertical-align: top;" width="35%">Vendor Name</td>
                <td style="vertical-align: top;" width="2%">:</td>
                <td style="vertical-align: top;" width="63%">{{ tr.supplier.Name.replace("(GT)", "").replace("(LMT)", "")  }}</td>
              </tr>
              <tr style="">
                <td style="vertical-align: top;">Vendor Address</td>
                <td style="vertical-align: top;">:</td>
                <td style="vertical-align: top;">{{ tr.supplier.Address }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top;">Vendor NPWP</td>
                <td style="vertical-align: top;">:</td>
                <td style="vertical-align: top;">{{ tr.supplier.Npwp }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: 50%">
          <table width="100%">
            <tbody>
              <tr>
                <td style="vertical-align: top;" width="40%">Debit Note No</td>
                <td style="vertical-align: top;" width="2%">:</td>
                <td style="vertical-align: top;" width="58%">{{ tr.debitNote.Code }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top;">Debit Note Date</td>
                <td style="vertical-align: top;">:</td>
                <td style="vertical-align: top;">{{ dateFormat(tr.debitNote.SupplierCNDate) }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top;">Reference</td>
                <td style="vertical-align: top;">:</td>
                <td style="vertical-align: top;">{{ tr.debitNote.DnReferenceCode }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <br />
      <div class="d-flex">
        <div style="width: 50%">
          <table width="100%">
            <tbody>
              <tr>
                <td style="vertical-align: top;" width="35%">Purchase Org Name</td>
                <td style="vertical-align: top;" width="2%">:</td>
                <td style="vertical-align: top;" width="63%">{{ tr.company.Name }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top;">Purchase Org Address</td>
                <td style="vertical-align: top;">:</td>
                <td style="vertical-align: top;">{{ tr.company.Address }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top;">Purchase Org NPWP</td>
                <td style="vertical-align: top;">:</td>
                <td style="vertical-align: top;">{{ tr.company.Npwp }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style="width: 50%">
        </div>
      </div>

      <br />
      <div>
        <div>
          <table
            class="padding-table"
            style="width: 100%; border-collapse: collapse"
            v-if="tr.manualLines.length == 0"
          >
            <tbody>
              <tr class="text-center">
                <td style="border: 1px solid black; width: 10px;">
                  <strong>No</strong>
                </td>
                <td style="border: 1px solid black; width: 500px;">
                  <strong>Description</strong>
                </td>
                <td style="border: 1px solid black; width: 100px;">
                  <strong>Amount</strong>
                </td>
                <td style="border: 1px solid black; width: 100px;">
                  <strong>Vat (PPN)</strong>
                </td>
                <td style="border: 1px solid black; width: 100px;">
                  <strong>Total Amount</strong>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    padding-left:12px
                  "
                >   1
                </td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  {{ tr.debitNote.Notes }}
                </td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                  "
                  align="right"
                >
                  <span v-if="tr.debitNote.DPP == 0">{{ priceFormat(tr.debitNote.DNValue) }}</span>
                  <span v-else>{{ priceFormat(tr.debitNote.DPP) }}</span>
                </td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                  "
                  align="right"
                >
                  {{ priceFormat(tr.debitNote.TaxValue) }}
                </td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                  "
                  align="right"
                >
                  {{ priceFormat(tr.debitNote.TotalDnValue) }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    text-align: right;
                  "
                >
                  {{ priceFormat(tr.debitNote.TotalDnValue) }}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="padding-table"
            style="width: 100%; border-collapse: collapse"
            v-else
          >
            <tbody>
              <tr class="text-center">
                <td style="border: 1px solid black; width: 10px;">
                  <strong>No</strong>
                </td>
                <td style="border: 1px solid black; width: 500px;">
                  <strong>Description</strong>
                </td>
                <td style="border: 1px solid black; width: 100px;">
                  <strong>Amount</strong>
                </td>
                <td style="border: 1px solid black; width: 100px;">
                  <strong>Vat (PPN)</strong>
                </td>
                <td style="border: 1px solid black; width: 100px;">
                  <strong>Total Amount</strong>
                </td>
              </tr>
              <template v-for="(dt, dtIndex) in tr.manualLines">
                <tr :key="dtIndex">
                  <td
                    style="
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                    "
                  >  
                  <!-- {{ dtIndex+1 }} -->
                  {{ dtIndex + 1 + tr.chunkOffset }}
                  </td>
                  <td
                    style="
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                    "
                  >
                    <!-- {{ dt.item_name }} ({{ dt.unit_name }}) -->
                    {{ dt.Description }}
                  </td>
                  <td
                    style="
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                    "
                    align="right"
                  >
                    <!-- {{ priceFormat(dt.dn_value) }} -->
                    {{ priceFormat(dt.LineValue) }}
                  </td>
                  <td
                    style="
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                    "
                    align="right"
                  >
                    <!-- {{ priceFormat(dt.dn_tax) }} -->
                    {{ priceFormat(dt.TaxValue) }}
                  </td>
                  <td
                    style="
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                    "
                    align="right"
                  >
                    <!-- {{ priceFormat(dt.dn_total_value) }} -->
                    {{ priceFormat(dt.TotalValue) }}
                  </td>
                </tr>
              </template>
              <tr>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>TOTAL</td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    text-align: right;
                  "
                >
                  {{ priceFormat(tr.totalValue) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div style="margin-top: 5%; margin-left: 5%" v-if="indextr == debitNote.length - 1"> -->
      <div style="margin-left: 5%" v-if="tr.isSignature">
        <table width="100%">
          <tbody>
            <tr>
              <!-- <td width="15%">Yang Menerima,</td> -->
              <td width="40%">{{ tr.supplierAccount.BankName }}</td>
              <td></td>
              <td width="30%">Hormat Kami</td>
              <td></td>
            </tr>
            <tr>
              <td>{{ tr.supplierAccount.AccountNumber }} (Virtual Account)</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style="height: 70px"></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <!-- <td colspan="2">(............................)</td> -->
              <td colspan="2"></td>
              <td colspan="2">(............................)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      data: [],
      debitNote: [],
      supplierAccount: null,
    };
  },
  computed: {
    urlLogo: function () {
      return this.company == undefined
        ? "default.png"
        : this.company.company_logo;
    },
  },
  methods: {
    dateFormat(date) {
      if (date) {
        // let dt = new Date(date);
        // let d = dt.getDate();
        // let m = dt.getMonth() + 1;
        // let y = dt.getFullYear();
        // return d + "/" + m + "/" + y;
        return moment(date).format("D MMMM YYYY");
      }
    },
    dataValue(data) {
      this.data = data;
      console.log("data 1: ", this.data);
      for (let index = 0; index < data.length; index++) {
        const debitNote = data[index];
        console.log("data 2: ", debitNote);
        console.log("data 5: ", debitNote.debitNoteManualLines.length);
        let chunkOffset = 0;

        if (debitNote.debitNoteManualLines.length > 0) {
          for (let i = 0; i < debitNote.debitNoteManualLines.length; i += 5) {
            let noteCopy = { ...debitNote };
  
            console.log("data 1: ", noteCopy);
            let currentChunk = debitNote.debitNoteManualLines.slice(i, i + 5);
            noteCopy.manualLines = currentChunk;
  
            let totalValue = currentChunk.reduce((sum, line) => {
              return sum + (line.TotalValue || 0);
            }, 0);
  
            noteCopy.chunkOffset = chunkOffset;
            noteCopy.totalValue = totalValue;
            // console.log("data: ", noteCopy);
            noteCopy.isSignature = false;
            if (currentChunk.length < 5) {
              noteCopy.isSignature = true;
            }
            console.log("data chunkOffset: ", chunkOffset);
            console.log("data currentChunk: ", currentChunk);
  
            chunkOffset += currentChunk.length;
            this.debitNote.push(noteCopy);
          }
        } else {
          let noteCopy = { ...debitNote };
          noteCopy.manualLines = [];
          noteCopy.isSignature = true;

          this.debitNote.push(noteCopy)
          console.log("length debitnote", this.debitNote.length);
        }
      }

      console.log("data: ", this.debitNote);

      return true;
    },
  },
  components: {},
  mounted() {
    this.company = this.$userLogin;
    console.log("this.$route.query.ids", this.$route.query.ids)
    this.$http
      .get("api/v1/debit-note/dn-lines", {
        params: {
          ids: this.$route.query.ids
        },
      })
      .then((resp) => {
        if (resp.code == 200) {
          console.log(resp.data);
          this.dataValue(resp.data);
          this.$nextTick(() => {
            window.print();
          });
          this.$vs.loading.close();
        } else {
          this.$vs.dialog({
            type: "alert",
            color: "danger",
            title: `Alert`,
            text: "Data not found",
          });
          this.$vs.loading.close();
        }
      });
  },
};
</script>

<style lang="scss">
body {
  height: 5.98in;
  width: 8.50in;
  margin: 0 auto;
  background-color: white;
  padding: 0;
  font-size: 16.5px;
  font-family: Times New Roman;
  //font-weight: bold;
  color: #000000;
}
div {
  margin-left: 0.3%;
  margin-right: 1.2%;
}

.d-flex {
  display: flex;
}

.color {
  background-color: #d0cdf2;
}

table.padding-table tr td {
  padding: 3px;
  font-family: Calibri;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

* {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

// @page {
//   size: A5;
//   margin: 0;
// }

.popup {
  width: 100%;
  //height: 100%;
  position: absolute;
  top: 10;
  left: 0;
  display: none;
}

.popup:nth-of-type(2) {
  page-break-before: always;
  top: 100%;
}

@media print {
  .printable * {
    visibility: visible;
  }
  .popup {
    display: block;
  }
  @page {size: 5.98in 8.5in;size: landscape;margin-left: 0.15in;}
  .pagebreak { page-break-before: always; }
}

</style>